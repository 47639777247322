@import 'variables.scss';

.BarIdentification {
    display: flex;
    margin-right: auto;
    flex-direction: column;
    background-color: $white;
    padding: 20px 0;

}

h1 {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
}

p {
    margin: 0;
}