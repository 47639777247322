@import 'variables.scss';

.container {
  display: flex;
  gap: 25px;
  padding: 30px 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(50vh);
}

.errorContainer {
  margin: 0.6rem;
  padding: 2rem;
  text-align: center;

  h2 {
    color: $dark;
    margin: 0;
  }
}
